<script>
/* eslint-disable */

import {checaDisponibilidade} from "@/domain/integra/services/grupo-porto";

export default {
  name: 'WSGrupoPorto',
  inject: ['page'],
  mixins: [],
  components: {},
  data() {
    return {
      loading: true,
      disponibilidade: null
    }
  },
  computed: {},
  mounted() {
    this.page.setActive('basic')
    document.querySelector('.u-erp-layout-container').classList.add('app-globalconfig')
    this.checaDisponibilidade()
  },
  beforeDestroy() {
    document.querySelector('.u-erp-layout-container').classList.remove('app-globalconfig')
  },
  methods: {
    checaDisponibilidade () {
      checaDisponibilidade()
      .then(({data}) => {
        this.loading = false
        this.disponibilidade = data
        console.log(data)
      })
      .catch(error => {
        this.alertApiError(error)
      })
    }
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap globalconfigs-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="global-config-container">
          <div class="m-t m-b-lg">
            <!--            <h2 class="i-title">Consulta de veículos disponíveis para leilão</h2>
                        <p class="m-b-xxl">Integração com o Grupo Porto</p>-->
            <h2 class="component-title">Olá, seja bem vindo(a) ao serviço de integração com Seguradoras (Grupo Porto e Youse)</h2>

            <div class="m-t-lg">
              <div v-if="loading">
                <i class="fa fa-spin fa-spinner"></i> Aguarde enquanto verificamos a disponibilidade do ambiente.
              </div>
              <div v-if="disponibilidade">
                <div class="erp-terminal" style="padding: 30px; color: #b6ffa7 !important;">
                  Ambientes disponíveis: <br><br>
                  <table>
                    <tr>
                      <td style="font-weight: 200; padding-right: 30px">Azul Seguros:</td>
                      <td><span v-if="disponibilidade.statusAzul">Sim</span><span v-else>Não</span></td>
                    </tr>
                    <tr>
                      <td style="font-weight: 200; padding-right: 30px">Porto Seguro:</td>
                      <td><span v-if="disponibilidade.statusPorto">Sim</span><span v-else>Não</span></td>
                    </tr>
                    <tr>
                      <td style="font-weight: 200; padding-right: 30px">Youse:</td>
                      <td><span v-if="disponibilidade.statusYouse">Sim</span><span v-else>Não</span></td>
                    </tr>
                  </table>
                </div>

                <div class="m-t-lg">
                  <div><e-btn label="Consultar veículos disponíveis para leilão" @click="$router.push({name: 'integra.grupo-porto.consultaLeilao'})" /></div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
